.container {
  min-height: 100%;
  background-color: #f7f8fa;

  .btnBox {
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }

  .opus_list {
    display: flex;

    .opus_item {
      margin-right: 10px;
      position: relative;
      width: 160px;
      height: 200px;
      border-radius: 4px;
      overflow: hidden;

      .mark_box {
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}

.groupInfo {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
}
